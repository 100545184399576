angular.module('RocketWash').component('rwReservationStatus', {
  templateUrl: 'components/rw-reservation/common/status/index.slim',
  controllerAs: 'vm',
  controller: function ($scope, $uibModal, $uibModalStack, $translate, userSession, reservationForm,SettingValue) {
    $scope.rf = reservationForm;

    $scope.reservationIdText = () => {
      const reservation = reservationForm.r();
      if (reservation.id) {

        return `Заказ №${reservation.ordinal}.`
      } else {
        return '';
      }
    };

    $scope.canCancelReservation = () => {
      const rf = reservationForm;
      const isOrgDirector = userSession.roles.map(role => role.name).includes('organization_director');
      const reservation = rf.reservations.reservation;
      const isReservationNotSaved = !reservation.id;
      const yandexPaymentsConst = ['ya_fuel'];
      const PaymentDistanitionToSkip = rf.config.constants.payment_destinations.filter(pd => yandexPaymentsConst.includes(pd.payment_type));
      const arrIdsPDtoSkip = PaymentDistanitionToSkip.map(pd => pd.id);
      const inPaymentsExistsYandex = rf.payments.all.find(p => p.payment_destination_id && p.payment_destination_id.includes(arrIdsPDtoSkip));
      const PaidedYandex = inPaymentsExistsYandex && inPaymentsExistsYandex.amount > 0;
      return isReservationNotSaved ? true : ((!PaidedYandex) || (userSession.superuser || isOrgDirector));
    };

      $scope.reservationStatus = () => {
      const reservation = reservationForm.r();

      if (!reservation.id) {
        return 'not_saved';
      }

      if (reservation.status === 'cancelled') {
        return 'cancelled';
      }

      if (reservation.status === 'cancelled_by_client') {
        return 'cancelled_by_client';
      }

      if (reservation.status === 'cancelled_by_ya_fuel_online') {
        return 'cancelled_by_ya_fuel_online';
      }

      let status = [
        (reservation.status === 'success') ? 'completed' : 'not_completed',
        reservation.fully_paid ? 'paid' : 'not_paid',
      ].join('_');

      return status;
    };

    $scope.cancel = () => {
      if (reservationForm.r().readonly) { return; }

      $uibModal.open({
        templateUrl: 'pages/common/confirmation-popup/index.slim',
        controller: 'CommonConfirmationPopupController',
        resolve: {
          config: {
            title: 'Отмена заказа',
            text: `Вы уверены, что хотите отменить заказ?`,
            buttons: [
              {
                text: 'Нет',
                class: 'btn blue-btn fix-btn',
                click: (scope) => {
                  scope.$dismiss();
                }
              },
              {
                text: 'Да',
                class: 'right btn white-btn fix-btn',
                click: (scope) => {
                  reservationForm.reservations.cancel();
                }
              },
            ]
          },
        },
      });
    };

    $scope.servicesFinished = () => {
      if (reservationForm.r().readonly) { return; }

      reservationForm.reservations.servicesFinished();
    };


    $scope.showBookBtnYaFuelOnlineReservation = () => {
      const reservation = reservationForm.r();

      const isYaFuelOnlineChannel = reservation.channel === 'ya_fuel_online';
      const isYaFuelOnlineStatusNull = reservation.ya_fuel_online_status == null;
      const isAutoBookingDisabled = !reservationForm.config.settings.service_location_automatically_book_for_ya_fuel_online;

      return isYaFuelOnlineChannel && isYaFuelOnlineStatusNull && isAutoBookingDisabled;
    };

    $scope.showProcessBtnYaFuelOnlineReservation = () => {
      const reservation = reservationForm.r();

      const isYaFuelOnlineChannel = reservation.channel === 'ya_fuel_online';
      const isYaFuelOnlineStatusBook = reservation.ya_fuel_online_status == 'book';

      return isYaFuelOnlineChannel && isYaFuelOnlineStatusBook;

    };

    $scope.yaFuelOnlineStartProcess = () => {
      reservationForm.reservations.yaFuelOnlineStartProcess();
    };

    $scope.yaFuelOnlineBooked = () => {
      reservationForm.reservations.yaFuelOnlineBooked();
    };

    $scope.finish = () => {
      if (reservationForm.r().readonly) { return; }

      reservationForm.reservations.finish();
    };

    $scope.backToWork = () => {
      reservationForm.reservations.backToWork();
    };
  },
});
