import L from 'leaflet';

const LOCAL_STORAGE_STATE_KEY = 'LOCAL_STORAGE_ONLINE_RESERVATIONS_STATE_KEY';

angular.module('RocketWash').controller('AdminOnlineReservationsDashboardIndexController', (
  $window, $rootScope, $scope, $timeout, Reservation, ServiceLocation, userSession, dateRangeStorage,
) => {
  $scope.adminStatusVariants = [
    'new', 'processed', 'approved', 'declined'
  ]

  $scope.pds = $scope.pageDataService.new({
    page: 'online_reservations',
    filters: {
      attributes: [
        { name: 'time_start', type: 'datetime' },
        { name: 'time_end', type: 'datetime' },
        { name: 'organization_name', type: 'string' },
        { name: 'service_location_name', type: 'string' },
        { name: 'ya_fuel_online_id', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'phone', type: 'string' },
        {
          name: 'status',
          type: 'enum',
          values: [
            { value: 'new', label: 'Новый' },
            { value: 'success', label: 'Успешно завершен' },
            { value: 'fail', label: 'Ошибка' },
            { value: 'cancelled', label: 'Отменен оператором' },
            { value: 'cancelled_by_client', label: 'Отменен клиентом' },
            { value: 'cancelled_by_ya_fuel_online', label: 'Отменен со стороны Яндекса' },
          ],
        },
        {
          name: 'admin_status',
          type: 'enum',
          values: [
            { value: 'new', label: 'Новый' },
            { value: 'processed', label: 'Обработан' },
            { value: 'approved', label: 'Успешно завершен' },
            { value: 'declined', label: 'Отменен' },
          ],
        },
        { name: 'total_price', type: 'number' },
        {
          name: 'channel',
          type: 'enum',
          values: [
            { value: 'offline', label: 'Офлайн' },
            { value: 'mobile', label: 'Приложение RocketWash' },
            { value: 'mobile_individual', label: 'Индивидуальное приложение' },
            { value: 'ya_maps', label: 'Яндекс Карты' },
            { value: 'ya_fuel_online', label: 'Заправки онлайн' },
            { value: 'reservation_by_link', label: 'Запись по ссылке' },
            { value: 'carbox', label: 'Carbox' },
            { value: 'leyka', label: 'Лейка' },
          ],
        },
        { name: 'order_ordinal', type: 'number' },
      ],
      scopes: ['organization_name', 'service_location_name', 'name', 'phone', 'make_model', 'tag', 'ya_fuel_online_id'],
    },
    pg: {
      url: '/admin/online_reservations',
      model: Reservation,
      query: {
        fromToField: 'created_at',
        predicate: '-created_at',
      },
    },
    definePaginateMethodsOn: $scope,
  });
  $scope.selected = {dateField: 'created_at'};
  $scope.dateFields = ['created_at', 'time_start', 'time_end'];
  $scope.setSearchDateField = (value) => {
    $scope.pds.pg.query.fromToField = value;
    $scope.pds.pg.query.predicate = `-${value}`;
    $scope.updatePage();
  };

  $scope.approve = (reservation) => {
    reservation.adminStatus = 'approved';
    $scope.save(reservation);
  };
  $scope.decline = (reservation) => {
    reservation.adminStatus = 'declined';
    $scope.save(reservation);
  };
  $scope.save = (reservation) => {
    return reservation.save({organizationId: reservation.organizationId});
  };

  $scope.search = {
    radius: 500000,
    coordinates: {
      lat: 60.86388876054231,
      lng: 27.637996673583988,
    },
  };

  $scope.afterMapInit = (target) => {
    $scope.map = target;

    const savedState = $window.localStorage.getItem(LOCAL_STORAGE_STATE_KEY);
    if (savedState) {
      $scope.state = JSON.parse(savedState);

      angular.extend($scope.search, $scope.state.search);

      if ($scope.state.search.coordinates) {
        $scope.map.setView(
          angular.copy($scope.state.search.coordinates),
          8,
        );
      }
      dateRangeStorage.update($scope.state.dateRange);
    };

    $scope.$watch('search', () => {
      showCircleRadius();
      let query = $scope.pds.pg.query;
      [query.lat, query.lon] = [$scope.search.coordinates.lat, $scope.search.coordinates.lng];
      query.radius = $scope.search.radius;

      saveState();

      $scope.updatePage();
    }, true);
  };

  const saveState = () => {
    const state = JSON.stringify({
      search: $scope.search,
      dateRange: dateRangeStorage.range(),
    });
    $window.localStorage.setItem(LOCAL_STORAGE_STATE_KEY, state);
  };

  $rootScope.$on('date-range-updated', () => {
    saveState();
  });

  $scope.goToServiceLocation = (reservation) => {
    userSession.pretend({
      role: 'organization_director',
      organizationId: reservation.organizationId,
      serviceLocationId: reservation.serviceLocationId,
      inNewTab: true,
    });
  };

  const map = L.map('online-reservations-map').setView([53.750732672433465, 27.607492926269526], 8);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);


  map.on('click', (e) => {
    $timeout(() => {
      $scope.search.coordinates = e.latlng;
    });
  });

  $scope.afterMapInit(map);

  $scope.circleLayer = null;
  const showCircleRadius = () => {

    const center = $scope.search.coordinates;

    const geojsonMarkerOptions = {
      radius: parseInt($scope.search.radius),
      fillColor: "#ff7800",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.4
    };

    if ($scope.circleLayer) {
      $scope.circleLayer.remove();
    }

    $scope.circleLayer = L.circle([center.lat, center.lng], geojsonMarkerOptions);

    $scope.circleLayer.addTo(map);
  }

});
